import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Table, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import { addTokenHeader, logout } from '../utils';

const UserList = (props) => {
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const roleEnum = {
        'SUPER': '超级用户',
        'MANAGER': '管理员',
        'USER': '普通用户'
    }

    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        axios.get('/api/users', addTokenHeader())
            .then(res => {
                setUsers(res.data);
                setLoading(false);
                props.toggleLogin(true);
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false)
                }
                else {
                    window.alert(error.response.data.msg);
                    history.goBack();
                }
            })
    }

    const handleOnDelete = (userID) => {
        const deleteConfirm = window.confirm("你是否确定删除本用户？")
        if (deleteConfirm) {
            axios.delete(`/api/user/${userID}/delete`, addTokenHeader())
            .then(res => {
                setLoading(true);
                getUsers();
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false)
                }
                else {
                    window.alert(error.response.data.msg);
                }
            })
        }
    }

    const handleOnEdit = (userID) => {
        history.push(`/user/${userID}/edit`);
    }

    return (
        <div>
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">用户列表</h1>
                <div style={{marginTop: 30}}>
                    {
                        loading ?
                        <div style={{position: "relative"}}>
                            <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                        </div> :
                        <Table bordered>
                            <thead>
                                <tr>
                                <th>用户名</th>
                                <th>用户邮箱</th>
                                <th>用户类</th>
                                <th>行为</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.map(user => {
                                        return (
                                            <tr key={user._id}>
                                                <td>{user.username}</td>
                                                <td>{user.email}</td>
                                                <td>{roleEnum[user.role]}</td>
                                                <td style={{width: 200, textAlign: 'center'}}>
                                                    <Button 
                                                        variant="primary"
                                                        onClick={() => handleOnEdit(user._id)}
                                                    >
                                                        更新
                                                    </Button>{' '}
                                                    <Button 
                                                        variant="danger" 
                                                        onClick={() => handleOnDelete(user._id)}
                                                    >
                                                        删除
                                                    </Button>{' '}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(UserList);
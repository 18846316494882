import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Card, Form, Button, Row, Col } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import NotFound from './NotFound';
import { addTokenHeader, addTokenHeaderForm, logout } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('zh-CN', zh)

const EditCredential = (props) => {
    const [loading, setLoading] = useState(true);
    const [newCertificateFile, setCertificateFile] = useState(null);
    const [credential, setCredential] = useState(null);
    const [notFound, setNotfound] = useState(false);
    let history = useHistory();

    const informationObject = {
        certificate_name: '证书名称',
        certificate_code: '证书编号',
        issuing_unit: '发证单位',
        issue_date: '发证时间',
        valid_until: '有效期至',
    }

    useEffect(() => {
        const supplierID = props.match.params.id;
        const credentialID = props.match.params.credid;
        axios.get(
            `/api/supplier/${supplierID}/credentials/${credentialID}/edit`, addTokenHeader())
            .then(res => {
                setCredential(res.data)
                setLoading(false)
            })
            .catch(error => {
                if (error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false);
                }
                else if (error.response.status === 401) {
                    history.goBack();
                }
                else if (error.response.status === 404) {
                    setNotfound(true)
                    setLoading(false)
                }
            })
    }, [history, props])

    const handleOnChange = e => {
        const { name, value } = e.target;
        setCredential(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOnIssueDateChange = e => {
        setCredential(prevState => ({
            ...prevState,
            ['issue_date']: e
        }));
    };

    const handleOnValidUntilChange = e => {
        setCredential(prevState => ({
            ...prevState,
            ['valid_until']: e
        }));
    };

    const handleCertificateUpload = (event) => {
        const uploadFile = event.target.files[0]
        setCertificateFile(uploadFile);
    }
    
    const handleUpdateSupplier = () => {
        const supplierID = props.match.params.id;
        const credentialID = props.match.params.credid;
        const payload = new FormData();
        for (const key in credential) {
            payload.append(key, credential[key])
        }
        payload.append('new_certificate_file', newCertificateFile);
        console.log(payload);
        axios.post(`/api/supplier/${supplierID}/credentials/${credentialID}/edit/submit`, payload, addTokenHeaderForm())
        .then(res => {
            history.push(`/supplier/${supplierID}`)
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 400) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
            }
            else {
                window.alert(error.response.data.msg)
            }
        });
    }

    const handleOnSubmit = () => {
        if (window.confirm('你确定是否想更新证书?')) handleUpdateSupplier()
    }

    return notFound ? <NotFound /> : (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    更新证书
                </h1>
                {
                    loading ?
                    <div style={{position: "relative"}}>
                        <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                    </div> :
                    <div className="container" style={{marginTop: 30, maxWidth: 700}}>
                        <Card>
                            {console.log(credential)}
                            <Card.Body>
                                {
                                    credential &&
                                    <Form>
                                        <h4 className='text-center'>证书信息</h4>
                                        {
                                            Object.keys(informationObject).map((i, att) => {
                                                return (
                                                    <Form.Group key={i}>
                                                        <Form.Label>{informationObject[i]}</Form.Label>
                                                        {
                                                            informationObject[i] === '发证时间' ?
                                                            <div>
                                                                <DatePicker selected={credential[i] ? new Date(credential[i]) : ''} locale='zh-CN' onChange={handleOnIssueDateChange} />
                                                            </div> :
                                                            informationObject[i] === '有效期至' ?
                                                            <div>
                                                                <DatePicker selected={credential[i] ? new Date(credential[i]) : ''} locale='zh-CN' onChange={handleOnValidUntilChange} />
                                                            </div> :
                                                            <Form.Control type="text" name={i} value={credential[i] ? credential[i] : ''} onChange={handleOnChange} />
                                                        }
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        <Form.Group controlId="formFile" className='mb-3'>
                                            <Form.Label>证书扫描件上传  {credential['certificate_file_short']}</Form.Label>
                                            <Form.Control type="file" onChange={handleCertificateUpload} />
                                        </Form.Group>
                                    </Form>
                                }
                            </Card.Body>
                            <div style={{textAlign: 'center', paddingBottom: 30}}>
                                <Button variant="primary" onClick={handleOnSubmit}>更新证书</Button>{' '}
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(EditCredential);
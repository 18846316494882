import React from 'react';
import { useHistory } from "react-router-dom";
import { Table, Pagination } from 'react-bootstrap';
import { useTable, usePagination, useAsyncDebounce, useGlobalFilter } from 'react-table';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
  
    return (
        <span>
            搜索:{' '}
            <input
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} 对象...`}
                style={{
                    fontSize: '1.1rem',
                    border: '0',
                }}
            />
        </span>
    )
}


const SupplierTable = (props) => {
    const history = useHistory();
    const dataRows = []
    for (var i=0; i<props.data.length; i++){
        dataRows.push(
            {
                type1: props.data[i].type1,
                type2: props.data[i].type2,
                supply_name: props.data[i].supply_name,
                supplier_name: props.data[i].supplier_name,
                operator: props.data[i].operator,
                operator_phone_number: props.data[i].operator_phone_number,
                operator_email: props.data[i].operator_email,
                supply_source: props.data[i].supply_source,
                id: props.data[i]._id
            }
        )
    }

    const data = React.useMemo(
        () => dataRows,
        []
    )
 
   const columns = React.useMemo(
       () => [
           {
               Header: '类别1',
               accessor: 'type1',
            },
            {
                Header: '类别2',
                accessor: 'type2',
            },
            {
                Header: '供应物资名称',
                accessor: 'supply_name',
            },
            {
                Header: '供应商名称',
                accessor: 'supplier_name',
            },
            {
                Header: '经办系人',
                accessor: 'operator',
            },
            {
                Header: '经办人电话',
                accessor: 'operator_phone_number',
            },
            {
                Header: '经办人邮箱',
                accessor: 'operator_email',
            },
            {
                Header: '供应商来源',
                accessor: 'supply_source',
            },
            {
                Header: 'id',
                accessor: 'id'
            }
        ],
        []
    )

    const handleOnClick = (itemId) => {
        history.push(`/supplier/${itemId}`)
    }
 
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter }
      } = useTable(
          {
            columns,
            data,
        },
        useGlobalFilter,
        usePagination
    )
 
    return (
        <div>
            <Table {...getTableProps()} striped bordered hover>
                <thead>
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                        </th>
                    </tr>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.slice(0, -1).map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                        <tr {...row.getRowProps()} 
                            style={{cursor: 'pointer'}} 
                            onClick={() => handleOnClick(row.original.id)}
                        >
                            {
                                row.cells.slice(0, -1).map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div className="pagination">
                <Pagination>
                    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage}/>
                    <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage}/>
                    <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage}/>
                    <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}/>
                </Pagination>
                <span>
                    {`Page ${pageIndex + 1} of ${pageOptions.length}  | Go to page: `}
                
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px', height: 37 }}
                    />
                </span>
                <select
                    value={pageSize}
                    style={{height: 37}}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

export default SupplierTable;
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import { logout, addTokenHeader } from '../utils';

const NewUser = (props) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('USER');
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(() => {
        props.role !== 'SUPER' && history.push("/");
    }, [props.role, history])

    const handleOnUsernameUpdate = (e) => {
        e.preventDefault();
        setUsername(e.target.value);
    }

    const handleOnEmailUpdate = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    }

    const handleOnPasswordUpdate = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    }

    const handleOnRoleUpdate = (e) => {
        setRole(e.target.value);
    }

    const handleOnSubmit = (e) => {
        const payload = {
            username,
            email,
            password,
            role
        };

        axios.post('/api/newuser/register', payload, addTokenHeader())
        .then(res => {
            setError('')
            history.push('/users');
        }).catch(error => {
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                setError(error.response.data.msg);
            }
        });
    };

    return (
        <div>
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    创造新用户
                </h1>
                <div className="container" style={{marginTop: 30, maxWidth: 600}}>
                    <Card>
                        <Card.Body>
                            <Form>
                                {
                                    error &&
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                }
                                <Form.Group controlId="formBasicUsername">
                                    <Form.Label>用户名</Form.Label>
                                    <Form.Control type="text" placeholder="输入用户名" onChange={handleOnUsernameUpdate} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>用户邮箱</Form.Label>
                                    <Form.Control type="email" placeholder="输入用户名" onChange={handleOnEmailUpdate} />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>密码</Form.Label>
                                    <Form.Control type="password" placeholder="输入密码" onChange={handleOnPasswordUpdate} />
                                </Form.Group>
                                <Form.Group controlId="formRole">
                                    <Form.Check id="formRole1" inline name="role" label="超级用户" value="SUPER" onChange={handleOnRoleUpdate} checked={role === "SUPER"} type="radio" />
                                    <Form.Check id="formRole2" inline name="role" label="管理员" value="MANAGER" onChange={handleOnRoleUpdate} checked={role === "MANAGER"} type="radio" />
                                    <Form.Check id="formRole3" inline name="role" label="普通用户" value="USER" onChange={handleOnRoleUpdate} checked={role === "USER"} type="radio" />
                                </Form.Group>
                                <div style={{textAlign: "center"}}>
                                    <Button variant="primary" onClick={handleOnSubmit}>
                                        创造新用户
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(NewUser);
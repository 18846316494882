import { Table } from 'react-bootstrap';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Button, ButtonGroup } from 'react-bootstrap';
import { Redirect, Link, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import { addTokenHeader, addTokenHeaderBlob, logout } from '../utils';

const CredentialsTable = (props) => {
    const { supplierID } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [credentials, setCredentials] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const history = useHistory();

    useEffect(() => {
        axios.get(
            `/api/supplier/${supplierID}/credentials`, addTokenHeader())
            .then(res => {
                setCredentials(res.data);
                setLoading(false);
                props.toggleLogin(true);
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false);
                }
                else if (error.response.status === 404) {
                    setNotFound(true);
                    setLoading(false);
                }
            })
    }, [])

    const processDownloadFile = (short_name, full_name, iterationNum) => {
        const payload = {file_path: credentials[iterationNum][full_name]}
        axios.post('/api/supplier/credential/download', payload, addTokenHeaderBlob())
        .then(response => {
            setError('')
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', credentials[iterationNum][short_name]);
            link.click();
        }).catch(error => {
            window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            }
        });
    }

    const processDelete = (credentialID) => {
        axios.delete(
            `/api/supplier/${supplierID}/credential/${credentialID}/delete`, addTokenHeader())
            .then(res => {
                window.alert("证书删除成功");
                window.location.reload();
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                }
                else {
                    window.alert(error.response.data.msg);
                }
            })
    }

    const handleOnDelete = (credentialID) => {
        const confirmDelete = window.confirm('你是否确定想删除证书');
        if (confirmDelete) processDelete(credentialID);
    }

    const credentialsObject = {
        certificate_name: '证书名称',
        certificate_code: '证书编号',
        issuing_unit: '发证单位',
        issue_date: '发证时间',
        valid_until: '有效期至',
        certificate_file_short: '证书扫描件'
    }

    return (
        <div>
            <Table responsive bordered>
                <thead>
                    <tr>
                        {
                            Object.keys(credentialsObject).map((i, att) => {
                                return (
                                    <th key={i}>{credentialsObject[i]}</th>
                                )
                            })
                        }
                        <th>
                            操作
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        credentials.map((credential, i) => {
                            return (
                                <tr key={i}>
                                    <td>{credential.certificate_name}</td>
                                    <td>{credential.certificate_code}</td>
                                    <td>{credential.issuing_unit}</td>
                                    <td>{credential.issue_date.substring(0, 10).replace(/-/g, "/")}</td>
                                    <td>{credential.valid_until.substring(0, 10).replace(/-/g, "/")}</td>
                                    <td style={{cursor: 'pointer', textDecoration: 'underline', color: '#007bff'}} 
                                        onClick={() => processDownloadFile('certificate_file_short', 'certificate_file', i)}>
                                        {credential.certificate_file_short}
                                    </td>
                                    <td><Link to={`${supplierID}/credentials/${credential._id}/edit`}>修改</Link>{' '}
                                        <span style={{ color: '#dc3545', cursor: 'pointer' }} onClick={() => handleOnDelete(credential._id)}>
                                            删除
                                        </span>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                ['SUPER', 'MANAGER'].includes(props.role) &&
                <div style={{textAlign: 'center', paddingBottom: 30}}>
                    <Link to={`${supplierID}/credentials/add`}>
                        <Button variant="primary">添加证书</Button>{' '}
                    </Link>
                </div>
            }
            {
                loading &&
                <div style={{position: "relative"}}>
                    <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                </div> 
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(CredentialsTable);
export const toggleLogin = (isLogin) => {
    return {
        type: 'LOGIN_TOGGLE',
        payload: isLogin
    }
};

export const toggleRole = (role) => {
    return {
        type: 'ROLE_TOGGLE',
        payload: role
    }
}
export const addTokenHeader = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
  
    if (token) {
        config.headers['x-auth-token'] = token;
    }
  
    return config;
};

export const addTokenHeaderForm = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    };

    if (token) {
        config.headers['x-auth-token'] = token;
    }
  
    return config;
};

export const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
}

export const addTokenHeaderBlob = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        responseType: 'blob'
    };
  
    if (token) {
        config.headers['x-auth-token'] = token;
    }
  
    return config;
};
import axios from 'axios';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import SupplierTable from '../components/SupplierTable';
import { addTokenHeader, logout } from '../utils';

class SupplierList extends React.Component {
    state = {
        loading: true,
        suppliersList: null
    }

    componentDidMount() {
        axios.get('/api/suppliers', addTokenHeader())
            .then(res => {
                this.setState({ 
                    suppliersList: res.data, 
                    loading: false
                });
                this.props.toggleLogin(true);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    this.props.toggleLogin(false);
                    this.props.toggleRole(null);
                    this.setState({
                        loading: false
                    })
                }
            })
    }

    render () {
        return (
            !this.state.loading &&
            !this.props.isLoggedIn ?
            <Redirect to ="/login" /> :
            <div>
                <SiteNav />
                <div style={{padding: 40}}>
                    <h1 className="text-center">
                        供应商列表
                    </h1>
                    <div style={{marginTop: 30}}>
                        {
                            this.state.loading ?
                            <div style={{position: "relative"}}>
                                <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                            </div> :
                            <SupplierTable data={this.state.suppliersList} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(SupplierList);
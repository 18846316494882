import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import SupplierList from '../pages/SupplierList';
import SingleSupplier from '../pages/SingleSupplier';
import EditSupplier from '../pages/EditSupplier';
import NewSupplier from '../pages/NewSupplier';
import UserList from '../pages/UserList';
import NewUser from '../pages/NewUser';
import EditUser from '../pages/EditUser';
import NotFound from '../pages/NotFound';
import NewSupplierCredential from '../pages/NewSupplierCredential';
import EditCredential from '../pages/EditCredential';

export default () => (
	<div>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={SupplierList}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/supplier/:id" exact component={SingleSupplier}/>
                <Route path="/supplier/:id/edit" exact component={EditSupplier}/>
                <Route path="/supplier/:id/credentials/add" exact component={NewSupplierCredential}/>
                <Route path="/supplier/:id/credentials/:credid/edit" exact component={EditCredential}/>
                <Route path="/newsupplier" exact component={NewSupplier}/>
                <Route path="/users" exact component={UserList}/>
                <Route path="/newuser" exact component={NewUser}/>
                <Route path="/user/:id/edit" exact component={EditUser} />
                <Route path="*" component={NotFound}/>
            </Switch>
        </BrowserRouter>
  	</div>
)
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Card, Form, Button, Row, Col } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import NotFound from '../pages/NotFound';
import { addTokenHeader, addTokenHeaderForm, logout } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('zh-CN', zh)

const EditSupplier = (props) => {
    const [loading, setLoading] = useState(true);
    const [newLicenceFile, setNewLicenceFile] = useState(null);
    const [newRepresentativeEmpowermentFile, setNewRepresentativeEmpowermentFile] = useState(null);
    const [newOperatorEmpowermentFile, setNewOperatorEmpowermentFile] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [notFound, setNotfound] = useState(false);
    let history = useHistory();

    const informationObject = {
        basicInfo: {
            supplier_name: '供应商名称',
            unified_credit_code: '统一信用代码',
            supplier_category: '供应商等级',
            location: '国家/地区',
            factory_address: '厂商地址',
            supply_source: '供应商来源',
            business_scope: '企业性质',
            date_founded: '单位成立时间'
        },
        equipmentInfo: {
            type1: '类别1',
            type2: '类别2',
            supply_name: '供应物资名称',
            supply_type: '供应物资类别',
            supply_code: '供应商编码'
        },
        legalRepresentativeInfo: {
            legal_representative: '法定代表人',
            legal_representative_phone_number: '法定代表人电话号码',
            legal_representative_email: '法定代表人邮箱',
            legal_representative_position: '法定代表人职位'
        },
        operatorInfo: {
            operator: '经办人',
            operator_phone_number: '经办人电话号码',
            operator_email: '经办人邮箱',
            operator_position: '经办人职位'
        }
    }

    const businessScopes = ['无', '生产厂家', '加工制作厂家', '进口品牌代理商', '贸易商', '施工单位']

    useEffect(() => {
        const supplierID = props.match.params.id;
        axios.get(
            `/api/supplier/${supplierID}/edit`, addTokenHeader())
            .then(res => {
                setSupplier(res.data)
                setLoading(false)
            })
            .catch(error => {
                if (error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false);
                }
                else if (error.response.status === 401) {
                    history.goBack();
                }
                else if (error.response.status === 404) {
                    setNotfound(true)
                    setLoading(false)
                }
            })
    }, [history, props])

    const handleOnChange = e => {
        const { name, value } = e.target;
        setSupplier(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOnDateChange = e => {
        setSupplier(prevState => ({
            ...prevState,
            ['date_founded']: e
        }));
    };

    const handleBusinessScopeChange = (event) => {
        event.persist();
        setSupplier(prevState => ({
            ...prevState,
            ["business_scope"]: event.target.value
        }));
    }

    const handleLicenceUpload = (event) => {
        const uploadFile = event.target.files[0]
        setNewLicenceFile(uploadFile);
    }

    const handleRepresentativeEmpowermentUpload = (event) => {
        const uploadFile = event.target.files[0]
        setNewRepresentativeEmpowermentFile(uploadFile);
    }

    const handleOperatorEmpowermentUpload = (event) => {
        const uploadFile = event.target.files[0]
        setNewOperatorEmpowermentFile(uploadFile);
    }
    
    const handleUpdateSupplier = () => {
        const supplierID = props.match.params.id;
        const payload = new FormData();
        for (const key in supplier) {
            payload.append(key, supplier[key])
        }
        payload.append('new_legal_representative_empowerement_document', newRepresentativeEmpowermentFile);
        payload.append('new_operator_empowerement_document', newOperatorEmpowermentFile);
        payload.append('new_licence_file', newLicenceFile);
        console.log(payload);
        axios.post(`/api/supplier/${supplierID}/edit/submit`, payload, addTokenHeaderForm())
        .then(res => {
            history.push(`/supplier/${supplierID}`)
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 400) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
            }
            else {
                window.alert(error.response.data.msg)
            }
        });
    }

    const handleOnSubmit = () => {
        if (window.confirm('你确定是否想更新供应商?')) handleUpdateSupplier()
    }

    return notFound ? <NotFound /> : (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    更新供应商
                </h1>
                {
                    loading ?
                    <div style={{position: "relative"}}>
                        <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                    </div> :
                    <div className="container" style={{marginTop: 30, maxWidth: 700}}>
                        <Card>
                            {console.log(supplier)}
                            <Card.Body>
                                {
                                    supplier &&
                                    <Form>
                                        <h4 className='text-center'>基本信息</h4>
                                        {
                                            Object.keys(informationObject.basicInfo).map((i, att) => {
                                                return (
                                                    <Form.Group key={i}>
                                                        <Form.Label>{informationObject.basicInfo[i]}</Form.Label>
                                                        {
                                                            informationObject.basicInfo[i] === '单位成立时间' ?
                                                            <div>
                                                                <DatePicker selected={supplier[i] ? new Date(supplier[i]) : ''} locale='zh-CN' onChange={handleOnDateChange} />
                                                            </div> :
                                                            informationObject.basicInfo[i] === '企业性质' ?
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="select"
                                                                    className="my-1 mr-sm-2"
                                                                    id="inlineFormCustomSelectPref"
                                                                    onChange={handleBusinessScopeChange}
                                                                    value={supplier.business_scope}
                                                                    custom
                                                                >
                                                                    {
                                                                        businessScopes.map((scope, i) => {
                                                                            return <option key={i}>{scope}</option>
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group> :
                                                            <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} />
                                                        }
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        <Form.Group controlId="formFile" className='mb-3'>
                                            <Form.Label>营业执照上传  {supplier['licence_file_short']}</Form.Label>
                                            <Form.Control type="file" onChange={handleLicenceUpload} />
                                        </Form.Group>
                                        <hr />
                                        <h4 className='text-center'>设备信息</h4>
                                        {
                                            Object.keys(informationObject.equipmentInfo).map((i, att) => {
                                                return (
                                                    <Form.Group key={i}>
                                                        <Form.Label>{informationObject.equipmentInfo[i]}</Form.Label>
                                                            <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        <hr style={{marginTop: '2rem'}} />
                                        <h4 className='text-center'>法定代表人信息</h4>
                                        {
                                            Object.keys(informationObject.legalRepresentativeInfo).map((i, att) => {
                                                return (
                                                    <Form.Group key={i}>
                                                        <Form.Label>{informationObject.legalRepresentativeInfo[i]}</Form.Label>
                                                            <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        <Form.Group as={Col} controlId="formFile" className='mb-3'>
                                            <Form.Label>法定代表人授权书  {supplier['legal_representative_empowerement_document_short']}</Form.Label>
                                            <Form.Control type="file" onChange={handleRepresentativeEmpowermentUpload} />
                                        </Form.Group>
                                        <hr style={{marginTop: '2rem'}} />
                                        <h4 className='text-center'>经办人信息</h4>
                                        {
                                            Object.keys(informationObject.operatorInfo).map((i, att) => {
                                                return (
                                                    <Form.Group key={i}>
                                                        <Form.Label>{informationObject.operatorInfo[i]}</Form.Label>
                                                            <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                                    </Form.Group>
                                                )
                                            })
                                        }
                                        <Form.Group as={Col} controlId="formFile" className='mb-3'>
                                            <Form.Label>经办人授权书  {supplier['operator_empowerement_document_short']}</Form.Label>
                                            <Form.Control type="file" onChange={handleOperatorEmpowermentUpload} />
                                        </Form.Group>
                                    </Form>
                                }
                            </Card.Body>
                            <div style={{textAlign: 'center', paddingBottom: 30}}>
                                <Button variant="primary" onClick={handleOnSubmit}>更新供应商</Button>{' '}
                            </div>
                        </Card>
                    </div>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(EditSupplier);
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Button, ButtonGroup } from 'react-bootstrap';
import { Redirect, Link, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import SingleSupplierTable from '../components/SingleSupplierTable';
import NotFound from './NotFound';
import { addTokenHeader, addTokenHeaderBlob, logout } from '../utils';
import CredentialsTable from '../components/CredentialsTable';

const SingleSupplier = (props) => {
    const [supplierID, setSupplierID] = useState(props.match.params.id);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [supplier, setSupplier] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [activeButtonText, setActivteButtonText] = useState("basicInfo");
    const history = useHistory();

    useEffect(() => {
        setSupplierID(props.match.params.id);
        axios.get(
            `/api/supplier/${supplierID}`, addTokenHeader())
            .then(res => {
                setSupplier(res.data);
                setLoading(false);
                props.toggleLogin(true);
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    setLoading(false);
                }
                else if (error.response.status === 404) {
                    setNotFound(true);
                    setLoading(false);
                }
            })
    }, [])

    const processDelete = () => {
        setSupplierID(props.match.params.id);
        axios.delete(
            `/api/supplier/${supplierID}/delete`, addTokenHeader())
            .then(res => {
                window.alert("供应商删除成功");
                history.push('/');
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 400) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                }
                else {
                    window.alert(error.response.data.msg);
                }
            })
    }

    const processDownloadFile = (short_name, full_name) => {
        const payload = {file_path: supplier[full_name]}
        axios.post('/api/supplier/download', payload, addTokenHeaderBlob())
        .then(response => {
            setError('')
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', supplier[short_name]);
            link.click();
        }).catch(error => {
            window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            }
        });
    }

    const handleOnDelete = () => {
        const confirmDelete = window.confirm('你是否确定想删除供应商');
        if (confirmDelete) processDelete();
    }

    function isIso8601(string) {
        const isoPattern = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z)?$/;
        return isoPattern.test(string);
      }

    const informationObject = {
        basicInfo: {
            supplier_name: '供应商名称',
            unified_credit_code: '统一信用代码',
            supplier_category: '供应商等级',
            location: '国家/地区',
            factory_address: '厂商地址',
            licence_file_short: '供应商营业执照',
            date_founded: '单位成立时间',
            creation_date: '生成时间',
            business_scope: '企业性质',
            supply_source: '供应商来源'
        },
        equipmentInfo: {
            type1: '类别1',
            type2: '类别2',
            supply_name: '供应物资名称',
            supply_type: '供应物资类别',
            supply_code: '供应商编码'
        },
        legalRepresentation: {
            legal_representative: '法定代表人',
            legal_representative_phone_number: '法定代表人电话号码',
            legal_representative_email: '法定代表人邮箱',
            legal_representative_position: '法定代表人职位',
            legal_representative_empowerement_document_short: '法定代表人授权书',
        },
        operatorInfo: {
            operator: '经办人',
            operator_phone_number: '经办人电话',
            operator_email: '经办人邮箱',
            operator_position: '经办人职位',
            operator_empowerement_document_short: '经办人授权书',
        }
    }

    const handleClick = (text) => {
        setActivteButtonText(text);
    };

    return notFound ? <NotFound /> : (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    供应商
                </h1>
                <div style={{marginTop: 30}}>
                    {
                        loading ?
                        <div style={{position: "relative"}}>
                            <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                        </div> :
                        <div>
                            <div className='text-center' style={{marginBottom: 20}}>
                                <ButtonGroup aria-label="Basic example">
                                    <Button 
                                        variant={activeButtonText === "basicInfo" ? "primary" : "light"}
                                        onClick={() => handleClick("basicInfo")}
                                    >
                                        基本信息
                                    </Button>
                                    <Button 
                                        variant={activeButtonText === "equipmentInfo" ? "primary" : "light"}
                                        onClick={() => handleClick("equipmentInfo")}
                                    >
                                        设备信息
                                    </Button>
                                    <Button 
                                        variant={activeButtonText === "legalRepresentation" ? "primary" : "light"}
                                        onClick={() => handleClick("legalRepresentation")}
                                    >
                                        法定代表人信息
                                    </Button>
                                    <Button 
                                        variant={activeButtonText === "operatorInfo" ? "primary" : "light"}
                                        onClick={() => handleClick("operatorInfo")}
                                    >
                                        经办人信息
                                    </Button>
                                    <Button 
                                        variant={activeButtonText === "companyCredentials" ? "primary" : "light"}
                                        onClick={() => handleClick("companyCredentials")}
                                    >
                                        企业资质
                                    </Button>
                                </ButtonGroup>
                            </div>
                            {
                                activeButtonText in informationObject &&
                                <div>
                                    <SingleSupplierTable 
                                        supplier={supplier} 
                                        informationObject={informationObject} 
                                        activeButtonText={activeButtonText} 
                                        isIso8601={isIso8601}
                                        processDownloadFile={processDownloadFile}
                                    />
                                    {
                                        ['SUPER', 'MANAGER'].includes(props.role) &&
                                        <div style={{textAlign: 'center', paddingBottom: 30}}>
                                            <Link to={`${supplierID}/edit`}>
                                                <Button variant="primary">修改信息</Button>{' '}
                                            </Link>
                                            <Button variant="danger" onClick={handleOnDelete}>删除</Button>{' '}
                                        </div>
                                    }
                                </div>
                            }
                            {
                                activeButtonText === 'companyCredentials' &&
                                <CredentialsTable supplierID={supplierID} />
                            }
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(SingleSupplier);
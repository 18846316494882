import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Form, Button, Alert, Card, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import { logout, addTokenHeaderForm } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('zh-CN', zh)

const NewSupplier = (props) => {
    const initState = {
        type1: '',
        type2: '',
        supply_name: '',
        supply_type: '',
        supply_code: '',
        supplier_name: '',
        unified_credit_code: '',
        supplier_category: '',
        location: '',
        factory_address: '',
        supply_source: '',
        date_founded: new Date(),
        licence_file: '',
        id_file: '',
        business_scope: '',
        legal_representative: '',
        legal_representative_phone_number: '',
        legal_representative_email: '',
        legal_representative_position: '',
        legal_representative_empowerement_document: '',
        operator: '',
        operator_phone_number: '',
        operator_email: '',
        operator_position: '',
        operator_empowerement_document: ''
    }
    const [supplier, setSupplier] = useState(initState);
    const [error, setError] = useState();
    let history = useHistory();

    const informationObject = {
        basicInfo: {
            supplier_name: '供应商名称',
            unified_credit_code: '统一信用代码',
            supplier_category: '供应商等级',
            location: '国家/地区',
            factory_address: '厂商地址',
            supply_source: '供应商来源',
            business_scope: '企业性质',
            date_founded: '单位成立时间'
        },
        equipmentInfo: {
            type1: '类别1',
            type2: '类别2',
            supply_name: '供应物资名称',
            supply_type: '供应物资类别',
            supply_code: '供应商编码'
        },
        legalRepresentativeInfo: {
            legal_representative: '法定代表人',
            legal_representative_phone_number: '法定代表人电话号码',
            legal_representative_email: '法定代表人邮箱',
            legal_representative_position: '法定代表人职位'
        },
        operatorInfo: {
            operator: '经办人',
            operator_phone_number: '经办人电话号码',
            operator_email: '经办人邮箱',
            operator_position: '经办人职位'
        }
    }

    const businessScopes = ['无', '生产厂家', '加工制作厂家', '进口品牌代理商', '贸易商', '施工单位']

    useEffect(() => {
        !['SUPER', 'MANAGER'].includes(props.role) && history.push("/");
    }, [props.role, history])

    const handleOnChange = e => {
        const { name, value } = e.target;
        setSupplier(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOnDateChange = e => {
        setSupplier(prevState => ({
            ...prevState,
            ["date_founded"]: e
        }));
    };

    const handleLicenceUpload = (event) => {
        const uploadFile = event.target.files[0]
        setSupplier(prevState => ({
            ...prevState,
            ["licence_file"]: uploadFile
        }));
    }

    const handleLegalEmpowerUpload = (event) => {
        const uploadFile = event.target.files[0]
        setSupplier(prevState => ({
            ...prevState,
            ["legal_representative_empowerement_document"]: uploadFile
        }));
    }

    const handleOperatorEmpowerUpload = (event) => {
        const uploadFile = event.target.files[0]
        setSupplier(prevState => ({
            ...prevState,
            ["operator_empowerement_document"]: uploadFile
        }));
    }

    const handleBusinessScopeChange = (event) => {
        event.persist();
        setSupplier(prevState => ({
            ...prevState,
            ["business_scope"]: event.target.value
        }));
    }

    const handleOnSubmit = (e) => {
        const formData = new FormData();
        for (const key in supplier) {
            formData.append(key, supplier[key])
        }
        console.log(supplier);
        axios.post('/api/newsupplier/submit', formData, addTokenHeaderForm())
        .then(res => {
            setError('')
            const supplierID = res.data.id;
            history.push(`/supplier/${supplierID}`);
        }).catch(error => {
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            }
        });
    };

    return (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    创造新供应商
                </h1>
                <div className="container" style={{marginTop: 30, maxWidth: 700}}>
                <Card>
                    <Card.Body>
                        <Form>
                            {
                            error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }
                            <h4 className='text-center'>基本信息</h4>
                            {
                                Object.keys(informationObject.basicInfo).map((i, att) => {
                                    return (
                                        <Form.Group key={i}>
                                            <Form.Label>{informationObject.basicInfo[i]}</Form.Label>
                                            {
                                                informationObject.basicInfo[i] === '单位成立时间' ? 
                                                <div>
                                                    <DatePicker selected={supplier[i]} locale='zh-CN' onChange={handleOnDateChange} /> 
                                                </div>
                                                :
                                                informationObject.basicInfo[i] === '企业性质' ?
                                                <Form.Group>
                                                    <Form.Control
                                                        as="select"
                                                        className="my-1 mr-sm-2"
                                                        id="inlineFormCustomSelectPref"
                                                        onChange={handleBusinessScopeChange}
                                                        value={supplier.business_scope}
                                                        custom
                                                    >
                                                        {
                                                            businessScopes.map((scope, i) => {
                                                                return <option key={i}>{scope}</option>
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group> :
                                                <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} />
                                            }
                                        </Form.Group>
                                    )
                                })
                            }
                            <Form.Group controlId="formFile" className='mb-3'>
                                <Form.Label>营业执照上传</Form.Label>
                                <Form.Control type="file" onChange={handleLicenceUpload} />
                            </Form.Group>
                            <hr />
                            <h4 className='text-center'>设备信息</h4>
                            {
                                Object.keys(informationObject.equipmentInfo).map((i, att) => {
                                    return (
                                        <Form.Group key={i}>
                                            <Form.Label>{informationObject.equipmentInfo[i]}</Form.Label>
                                                <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                        </Form.Group>
                                    )
                                })
                            }
                            <hr style={{marginTop: '2rem'}} />
                            <h4 className='text-center'>法定代表人信息</h4>
                            {
                                Object.keys(informationObject.legalRepresentativeInfo).map((i, att) => {
                                    return (
                                        <Form.Group key={i}>
                                            <Form.Label>{informationObject.legalRepresentativeInfo[i]}</Form.Label>
                                                <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                        </Form.Group>
                                    )
                                })
                            }
                            <Form.Group controlId="formFile" className='mb-3'>
                                <Form.Label>法定代表人授权书扫描件</Form.Label>
                                <Form.Control type="file" onChange={handleLegalEmpowerUpload} />
                            </Form.Group>
                            <hr />
                            <h4 className='text-center'>经办人信息</h4>
                            {
                                Object.keys(informationObject.operatorInfo).map((i, att) => {
                                    return (
                                        <Form.Group key={i}>
                                            <Form.Label>{informationObject.operatorInfo[i]}</Form.Label>
                                                <Form.Control type="text" name={i} value={supplier[i] ? supplier[i] : ''} onChange={handleOnChange} required />
                                        </Form.Group>
                                    )
                                })
                            }
                            <Form.Group controlId="formFile" className='mb-3'>
                                <Form.Label>经办人授权书扫描件</Form.Label>
                                <Form.Control type="file" onChange={handleOperatorEmpowerUpload} />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <div style={{textAlign: 'center', paddingBottom: 30}}>
                        <Button variant="primary" onClick={handleOnSubmit}>创造本供应商</Button>{' '}
                    </div>
                </Card>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(NewSupplier);
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { registerLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import { logout, addTokenHeaderForm } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('zh-CN', zh)

const NewSupplierCredential = (props) => {
    const initState = {
        certificate_name: '',
        certificate_code: '',
        issuing_unit: '',
        issue_date: new Date(),
        valid_until: new Date(),
        certificate_file: ''
    }
    const [certification, setCertification] = useState(initState);
    const [error, setError] = useState();
    let history = useHistory();

    const informationObject = {
        certificate_name: '证书名称',
        certificate_code: '证书编号',
        issuing_unit: '发证单位',
        issue_date: '发证时间',
        valid_until: '有效期至',
    }

    useEffect(() => {
        !['SUPER', 'MANAGER'].includes(props.role) && history.push("/");
    }, [props.role, history])

    const handleOnChange = e => {
        const { name, value } = e.target;
        setCertification(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOnIssueDateChange = e => {
        setCertification(prevState => ({
            ...prevState,
            ["issue_date"]: e
        }));
    };

    const handleOnValidDateChange = e => {
        setCertification(prevState => ({
            ...prevState,
            ["valid_until"]: e
        }));
    };

    const handleCertificateUpload = (event) => {
        const uploadFile = event.target.files[0]
        setCertification(prevState => ({
            ...prevState,
            ["certificate_file"]: uploadFile
        }));
    }

    const handleOnSubmit = (e) => {
        const formData = new FormData();
        const supplierID = props.match.params.id;
        formData.append('supplier_id', supplierID)
        for (const key in certification) {
            formData.append(key, certification[key])
        }
        axios.post(`/api/supplier/${supplierID}/credentials/add`, formData, addTokenHeaderForm())
        .then(res => {
            setError('')
            history.push(`/supplier/${supplierID}`);
        }).catch(error => {
            if (error.response.status === 400 || error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login');
            }
            else {
                window.alert(error.response.data.msg);
                setError(error.response.data.msg);
            }
        });
    };

    return (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    添加新证书
                </h1>
                <div className="container" style={{marginTop: 30, maxWidth: 700}}>
                <Card>
                    <Card.Body>
                        <Form>
                            {
                            error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }
                            <h4 className='text-center'>证书信息</h4>
                            {
                                Object.keys(informationObject).map((i, att) => {
                                    return (
                                        <Form.Group key={i}>
                                            <Form.Label>{informationObject[i]}</Form.Label>
                                            {
                                                informationObject[i] === '发证时间' ? 
                                                <div>
                                                    <DatePicker selected={certification[i]} locale='zh-CN' onChange={handleOnIssueDateChange} />
                                                </div>
                                                :
                                                informationObject[i] === '有效期至' ?
                                                <div>
                                                    <DatePicker selected={certification[i]} locale='zh-CN' onChange={handleOnValidDateChange} />
                                                </div> :
                                                <Form.Control type="text" name={i} value={certification[i] ? certification[i] : ''} onChange={handleOnChange} />
                                            }
                                        </Form.Group>
                                    )
                                })
                            }
                            <Form.Group controlId="formFile" className='mb-3'>
                                <Form.Label>证书扫描件上传</Form.Label>
                                <Form.Control type="file" onChange={handleCertificateUpload} />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <div style={{textAlign: 'center', paddingBottom: 30}}>
                        <Button variant="primary" onClick={handleOnSubmit}>添加证书</Button>{' '}
                    </div>
                </Card>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(NewSupplierCredential);
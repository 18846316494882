import React from 'react';
import { Table } from 'react-bootstrap';

const SingleSupplierTable = (props) => {
    const { supplier, informationObject, activeButtonText, isIso8601, processDownloadFile } = props;

    return (
        <Table responsive bordered>
            <thead>
                <tr>
                    {
                        supplier &&
                        Object.keys(informationObject[activeButtonText]).map((i, att) => {
                            return (
                                <th key={i}>{informationObject[activeButtonText][i]}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                <tr>
                    {
                        supplier &&
                        Object.keys(informationObject[activeButtonText]).map((i, att) => {
                            return (
                                <td key={i}>{
                                    isIso8601(supplier[i]) ? 
                                    <span>{supplier[i].substring(0, 10).replace(/-/g, "/")}</span> : 
                                    informationObject[activeButtonText][i] === '法定代表人授权书' ?
                                    <span 
                                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#007bff'}} 
                                        onClick={() =>
                                            processDownloadFile(
                                                'legal_representative_empowerement_document_short', 'legal_representative_empowerement_document')}
                                    >
                                        {supplier[i]}
                                    </span> :
                                    informationObject[activeButtonText][i] === '经办人授权书' ?
                                    <span 
                                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#007bff'}} 
                                        onClick={() =>
                                            processDownloadFile(
                                                'operator_empowerement_document_short', 'operator_empowerement_document')}
                                    >
                                        {supplier[i]}
                                    </span> :
                                    informationObject[activeButtonText][i] === '供应商营业执照' ?
                                    <span 
                                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#007bff'}} 
                                        onClick={() =>
                                            processDownloadFile(
                                                'licence_file_short', 'licence_file')}
                                    >
                                        {supplier[i]}
                                    </span> :
                                    <span>{supplier[i]}</span>
                                }
                                </td>
                            )
                        })
                    }
                </tr>
            </tbody>
        </Table>
    )
}

export default SingleSupplierTable;